import tpsApiInstance from "./tpsApiBase";
import { 
  IUploadFileRequestParams,
  IUploadFileResponseSchema
} from './models/IUploadFile';

const IMPORTBYFILE_PATH = "/importByFile";

export const apiUploadFile = async (
    pData: IUploadFileRequestParams): Promise<IUploadFileResponseSchema> => {
      console.log("query: ",`${IMPORTBYFILE_PATH}?table=${pData.table}`);
    const res = await tpsApiInstance.post(`${IMPORTBYFILE_PATH}?table=${pData.table}`, pData.data);
    //console.log(res);
    return res?.data as IUploadFileResponseSchema;
};

