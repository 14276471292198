import tpsApiInstance from "./tpsApiBase";
import { IListMenuGetItemRequestParams } from './models/IListMenuGetItem';

const LIST_MENU_ITEM_PATH = "/listMenuItem";

export const apiListMenuGetItem = async(
  pData: IListMenuGetItemRequestParams): Promise<string[]> => {
  let query: string = "?";
  query += `menu=${pData.menuName}.${pData.listName}`;  
  if(pData && pData.card_company) query += `&card_company=${pData.card_company}`;
  console.log("query:",query);
  const res = await tpsApiInstance.get(`${LIST_MENU_ITEM_PATH}${query}`);
  console.log("apiListMenuGetItem:",res.data);
  return res?.data;
}

