
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";

@Options({
  emits: ["hide-form"]
})

export default class WarningModal extends Vue {
    showModal: boolean = false;
    
    setModalVisible(pVisible) {
        this.showModal = pVisible;
    }

    @Emit("hide-form")
    hideForm(pHide?: boolean) {
        this.showModal = false;

        if(pHide) { //Close form without save
            return false;
        }
        else {
            return null;
        }
    }
}
