import tpsApiInstance from "./tpsApiBase";
import {
    IOperationRuleList,
    IOperationRuleDetail,
    IOperationRuleGetListTotalNumRequestParams,
    IOperationRuleGetListRequestParams,
    IOperationRuleGetDetailRequestParams,
    IOperationRuleDetailUpdateRequestParams,
    IOperationRuleCreateResponseSchema,
    IOperationRuleDetailUpdateResponseSchema
} from './models/operationRule';

const LIST_OPERATION_RULE = "/listOperationRule";
const GET_OPERATION_RULE = "/getOperationRule";
const UPDATE_OPERATION_RULE = "/updateOperationRule";
const CREATE_OPERATION_RULE = "/createOperationRule";

export const apiOperationRuleGetListTotalNum = async(
    pData: IOperationRuleGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    
    const res = await tpsApiInstance.get(`${LIST_OPERATION_RULE}${query}`);
    return res?.data[0]?.total;
};

export const apiOperationRuleGetList = async(
    pData: IOperationRuleGetListRequestParams): Promise<IOperationRuleList> => {

    let query: string = "?";
    //if(pData && pData.status) query += `status=${pData.status}`;
    if(pData && pData.limit && pData.page) query += `&limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    console.log(query);
    const res = await tpsApiInstance.get(`${LIST_OPERATION_RULE}${query}`);
    console.log(res);
    return res?.data as IOperationRuleList;
};

export const apiOperationRuleGetDetail = async(
    pData:IOperationRuleGetDetailRequestParams): Promise<IOperationRuleDetail> => {
    const res = await tpsApiInstance.get(`${GET_OPERATION_RULE}?id=${pData.id}`);
    console.log(res?.data);
    return res?.data as IOperationRuleDetail;
};

export const apiOperationRuleCreate = async(
    pData: IOperationRuleDetail): Promise<IOperationRuleCreateResponseSchema> => {
    console.log(pData);
    const res = await tpsApiInstance.post(CREATE_OPERATION_RULE, pData);
    console.log(res);
    return res?.data as IOperationRuleCreateResponseSchema;
};

export const apiOperationRuleDetailUpdate = async (
    pData: IOperationRuleDetailUpdateRequestParams
    ): Promise<IOperationRuleDetailUpdateResponseSchema> => {
    console.log("Update:",JSON.stringify(pData));
    const res = await tpsApiInstance.post(`${UPDATE_OPERATION_RULE}?id=${pData.id}`, pData.data);
    console.log(res);
    return res.data as IOperationRuleDetailUpdateResponseSchema;
  };

export const apiOperationRuleGetVersion = async(): Promise<IOperationRuleList> => {
const res = await tpsApiInstance.get(`listOperationRule`);
const listNum = {};
if(res.data) {
    listNum[0] = '2000010101';
    let i;
    for (i = 0; i < res.data.length; i+=1) {
      if(res.data[i].invalid == 0){
        listNum[i+1] = res.data[i].version;
      }
    }
  }
return listNum;
};
  
