import tpsApiInstance from "./tpsApiBase";
import {
    IPaymentRuleList,
    IPaymentRuleDetail,
    IPaymentRuleGetListTotalNumRequestParams,
    IPaymentRuleGetListRequestParams,
    IPaymentRuleGetDetailRequestParams,
    IPaymentRuleDetailUpdateRequestParams,
    IPaymentRuleCreateResponseSchema,
    IPaymentRuleDetailUpdateResponseSchema
} from './models/paymentRule';

const LIST_Payment_RULE = "/listPaymentRule";
const GET_Payment_RULE = "/getPaymentRule";
const UPDATE_Payment_RULE = "/updatePaymentRule";
const CREATE_Payment_RULE = "/createPaymentRule";

export const apiPaymentRuleGetListTotalNum = async(
    pData: IPaymentRuleGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
    const res = await tpsApiInstance.get(`${LIST_Payment_RULE}${query}`);
    return res?.data[0]?.total;
};

export const apiPaymentRuleGetList = async(
    pData: IPaymentRuleGetListRequestParams): Promise<IPaymentRuleList> => {

    let query: string = "?";
    //if(pData && pData.status) query += `status=${pData.status}`;
    if(pData && pData.limit && pData.page) query += `&limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;
    if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    console.log(query);
    const res = await tpsApiInstance.get(`${LIST_Payment_RULE}${query}`);
    console.log(res);
    return res?.data as IPaymentRuleList;
};

export const apiPaymentRuleGetDetail = async(
    pData:IPaymentRuleGetDetailRequestParams): Promise<IPaymentRuleDetail> => {
    const res = await tpsApiInstance.get(`${GET_Payment_RULE}?id=${pData.id}`);
    console.log(res?.data);
    return res?.data as IPaymentRuleDetail;
};

export const apiPaymentRuleCreate = async(
    pData: IPaymentRuleDetail): Promise<IPaymentRuleCreateResponseSchema> => {
    console.log(pData);
    const res = await tpsApiInstance.post(CREATE_Payment_RULE, pData);
    console.log(res);
    return res?.data as IPaymentRuleCreateResponseSchema;
};

export const apiPaymentRuleDetailUpdate = async (
    pData: IPaymentRuleDetailUpdateRequestParams
    ): Promise<IPaymentRuleDetailUpdateResponseSchema> => {
    console.log("Update:",JSON.stringify(pData));
    const res = await tpsApiInstance.post(`${UPDATE_Payment_RULE}?id=${pData.id}`, pData.data);
    console.log(res);
    return res.data as IPaymentRuleDetailUpdateResponseSchema;
  };

  export const apiPaymentRuleGetVersion = async(pData: IPaymentRuleGetListRequestParams): Promise<IPaymentRuleList> => {
    let query: string = "?";
    if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
    const res = await tpsApiInstance.get(`${LIST_Payment_RULE}${query}`);
    const listNum = {};
    if(res.data) {
      listNum[0] = '2000010101';
      let i;
      for (i = 0; i < res.data.length; i+=1) {
        if(res.data[i].invalid == 0){
          listNum[i+1] = res.data[i].version;
        }
      }
    }
    console.log(listNum);
    return listNum;
  };
  
