export function arrangeUpdateData(pUpdateData: Object, pOriginData: Object) {
    let data = {} as any;
    for (var key of Object.keys(pUpdateData)) {
        if(pUpdateData[key] != pOriginData[key]){
            if(pUpdateData[key] == "" && pOriginData[key] == null) {
                //Do nothing
            }
            else if(pUpdateData[key] === "" && pOriginData[key] != null) {
                console.log("arrangeUpdateData:" + key + "," + pUpdateData[key] + ",origin:" + pOriginData[key]);
                data[key] = null;
            }
            else {
                console.log("arrangeUpdateData:" + key + "," + pUpdateData[key] + ",origin:" + pOriginData[key]);
                data[key] = pUpdateData[key];
            }
        }
    }

    return data;
}